import React from "react"
import Title from "../title"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import cn from "classnames"
import * as styles from "./benefits.module.scss"

export default function Benefits({ className }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={cn(styles.benefits, className)}>
          <header className={styles.header}>
            <Title headingLevel="h4">Sites with benefits</Title>
            <Title headingLevel="h2">How JAMstack supports your business</Title>
            <Title headingLevel="p">
              We use the power of JAMstack and server-side rendering to ship
              value to our customers.
            </Title>
          </header>
          <div className={styles.content}>
            <div className={styles.benefit}>
              <GatsbyImage
                image={data.swift.sharp.gatsbyImageData}
                style={{ width: 190 }}
                alt=""
              />
              <Title headingLevel="h3">Swift</Title>
              <p>
                We don’t use databases, so there’s no time-consuming multiple
                queries. Static sites are even up to 10x faster, with benefits
                for the UX and SERP rankings.
              </p>
            </div>
            <div className={styles.benefit}>
              <GatsbyImage
                image={data.safe.sharp.gatsbyImageData}
                style={{ width: 190 }}
                alt=""
              />
              <Title headingLevel="h3">Safe</Title>
              <p>
                73.2% of the most popular WordPress installations are opened to
                vulnerabilities. Statically rendered websites with no plugins
                are a fortress and let you save money on security.
              </p>
            </div>
            <div className={styles.benefit}>
              <GatsbyImage
                image={data.stable.sharp.gatsbyImageData}
                style={{ width: 190 }}
                alt=""
              />
              <Title headingLevel="h3">Stable</Title>
              <p>
                Traffic bigger than usual? Not an issue with the JAMstack sites
                - you host your websites’ static files in a multi-cloud
                environment on a CDN with no traffic limitations.
              </p>
            </div>
            <div className={styles.benefit}>
              <GatsbyImage
                image={data.growth.sharp.gatsbyImageData}
                style={{ width: 190 }}
                alt=""
              />
              <Title headingLevel="h3">Scalable</Title>
              <p>
                Implementing new features to your project? Serverless
                architecture scales automatically as your usage or user base
                extends, so you can scale your app or site without hassle.
              </p>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export const query = graphql`
  {
    swift: file(relativePath: { eq: "swift.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    safe: file(relativePath: { eq: "safe.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    stable: file(relativePath: { eq: "stable.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    growth: file(relativePath: { eq: "growth.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
