import React from "react"
import Seo from "../../../components/seo"
import Layout from "../../../components/layout"
import Title from "../../../components/title"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Section from "../../../components/section"
import Button from "../../../components/button"
import Grid from "../../../components/grid"
import Benefits from "../../../components/benefits"
import Technologies from "../../../components/technologies"
import * as styles from "./jamstack-website.module.scss"

export default function JamstackWebsitePage({ data }) {
  return (
    <>
      <Seo title="Blazing fast JAMstack websites can help you rank better among other things." />
      <Layout>
        <section className={styles.jamstack}>
          <header>
            <Title headingLevel="h4">JAMstack</Title>
            <Title headingLevel="h1">
              Get more customers with Seo-friendly marketing websites
            </Title>
            <Title headingLevel="p">
              There’s a thing Google and your customers have in common. They
              both like fast sites. Try JAMstack approach to get ranked higher
              and gain new visitors. Then, let your website turn them into
              customers.
            </Title>
            <Button to="#jamstack">View more</Button>
          </header>
          <div className={styles.jamstackHero}>
            <GatsbyImage image={data.words.sharp.gatsbyImageData} alt="" />
          </div>
        </section>

        <Section highlight id="jamstack">
          <Grid>
            <div>
              <Title headingLevel="h2">What is JAMstack</Title>
              <Title headingLevel="p">
                By definition, JAMstack is an alternative approach for building
                websites - the JAM here stands for JavaScript, APIs, and
                Markdown. For you, it means faster websites with better Seo
                ranking capabilities and happier users overall.
              </Title>
              <Title headingLevel="p">
                We build them using battle-tested technologies like GatsbyJS,
                Hugo, Contentful, Netlify, and other headless CMSes.
              </Title>
            </div>
            <div>
              <GatsbyImage image={data.gatsbyjs.sharp.gatsbyImageData} alt="" />
            </div>
          </Grid>
        </Section>

        <Section>
          <Grid className={styles.benefits}>
            <header>
              <Title headingLevel="h4">Sites with Benefits</Title>
              <Title headingLevel="h2">
                How JAMstack supports your marketing
              </Title>
            </header>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.performance.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Excellent performance</Title>
              <p>
                No database means no time-consuming multiple queries. Static
                sites are faster sites, even up to 10x.
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.seo.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Seo Advantage</Title>
              <p>
                Going static also means better ranking possibilities, what
                equals more traffic and more $$$.
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.safety.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Safety</Title>
              <p>
                Static websites with no plugins or databases are like a
                fortress. Plus, more secure site means fewer security expenses.
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.content.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">No-sweat content management</Title>
              <p>
                Headless CMSes let you build a website that is both easy to use
                and customize freely.
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.chart.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Reliable uptime & lower costs</Title>
              <p>
                Traffic bigger than usual? Not an issue here. With JAMstack
                marketing websites uptime is anytime. And doesn’t cost a
                fortune.
              </p>
            </div>
          </Grid>
        </Section>

        <Benefits className={styles.highlightedSection} />

        <Technologies />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    words: file(relativePath: { eq: "words.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    gatsbyjs: file(relativePath: { eq: "gatsbyjs.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    performance: file(relativePath: { eq: "performance.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    seo: file(relativePath: { eq: "seo.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    safety: file(relativePath: { eq: "safety.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    content: file(relativePath: { eq: "content.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    chart: file(relativePath: { eq: "chart.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
